//Dependencies
import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
  useRef,
  forwardRef
} from 'react';
import { useHoverIntent } from 'react-use-hoverintent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash.isequal';

//Chakra
import {
  Box,
  HStack,
} from '@chakra-ui/react';

//Components
import {
  ZoomButton,
} from '../components';

//Providers
import { useChart } from '../providers/chart';
import { useWaveFinder } from '../providers/wave-finder';
import { useView } from '../providers/view';

//Hooks
import { useBinaryChart, useWindowSize } from '../hooks';


const ChartFromBinary = (props) => {
  const { handleOnLoad, setChartBlob, innerRef, chartHeight } = props;
  const { activeGridView, isSingleView } =  useView();
  const { fullscreen, setWidth } = useChart();
  const { chartHTML } = useBinaryChart();

  useEffect(() => {
    if(chartHTML != null) {
      handleOnLoad();
    }
  }, [chartHTML, handleOnLoad]);

  useLayoutEffect(() => {
    const svgToBlob = (svgElement) => {
      const serializer = new XMLSerializer();
      const svgString = serializer.serializeToString(svgElement);
      return new Blob([svgString], { type: 'image/svg+xml' });
    };

    if(innerRef?.current) {
      const svg = innerRef.current.querySelector('svg');
      if(svg) {
        if((activeGridView.view === 'full' || isSingleView || fullscreen) && chartHeight) {
          svg.setAttribute('style', `max-height: ${chartHeight}px`);
          setWidth(svg.getBoundingClientRect().width);
        }
        setChartBlob(svgToBlob(svg));
      }
    }
  }, [chartHTML, innerRef, setChartBlob, chartHeight, setWidth, fullscreen, isSingleView, activeGridView.view]);

  return (
    <Box
      display={'flex'} justifyContent={'center'}
      className={'bchart'}
      ref={innerRef}
      dangerouslySetInnerHTML={{__html: chartHTML}}
    />
  );
}

const ChartFromBinaryWithRef = forwardRef((props, ref) => (
  <ChartFromBinary
    innerRef={ref}
    {...props}
  />
));


export default function Chart({children,...props}) {
  const {
    market,
    zoom,
    setZoom,
    fullscreen,
    setLoaded,
    setChartBlob,
  } = useChart();

  const [prevWaves, setPrevWaves] = useState(); //hold prev waves to compare on chart updates

  const [controls, setControls] = useState(false);
  const {query, waves} = useWaveFinder();

  const [isHovering, intentRef] = useHoverIntent();
  const [width, height] = useWindowSize();

  const zoomOutTest = useMemo(() => zoom > 0, [zoom]);
  const zoomInTest = useMemo(() => zoom < (parseInt(market.ZoomCount) - 1), [zoom, market]);
  const zoomOut = () => setZoom(z => z - 1);
  const zoomIn = () => setZoom(z => z + 1);

  const chartRef = useRef(null);
  const chartHeight = useMemo(() => height * (!fullscreen ? .85 : .95), [height, fullscreen]);

  // Handles showing and hiding controls for zoom UI components. Includes a hover intent delay.
  useEffect(() => {
    if(isHovering) {
      setControls(true);
    } else {
      setControls(false);
    }
  }, [isHovering]);

  // On load we set loaded to true and apply visibility to reveal the currently highlighted wave degree, also grab wave.degree if market doesn't have it
  const handleOnLoad = useCallback(() => {
    let marketWave;

    if(isEqual(waves, prevWaves)) {

      if(!market.Degree && waves) {
        marketWave = waves.filter(wave => wave.market === market.Id).map(wave => ({...market, Degree: wave.degree})).pop();
      } else {
        marketWave = market;
      }
      
      if(!fullscreen && marketWave && (typeof query === 'object' && Object.keys(query).length > 0)) {
        const waveId = '#wave'+marketWave.Degree;

        if(chartRef.current) {
          const waveEl = chartRef.current.querySelector(waveId);
          if(waveEl) {
            waveEl.style.visibility = 'visible';
          } else {
            console.error(`Current wave degree ${marketWave.Degree} was not found in chart!`, marketWave);
          }
        }
      } else {
        if(isEqual(query, {})) {
          const allWaves = chartRef.current.querySelectorAll('#waves > g');
          if(allWaves) {
            allWaves.forEach(wave => wave.style.visibility = null);
          }
        }
      }

      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [chartRef, market, waves, prevWaves, query, fullscreen, setLoaded]);

  useEffect(() => {
    setPrevWaves(waves)
  }, [waves, setPrevWaves]);

  return (
    <Box
      ref={intentRef}
      className={'chart-wrapper'}
      sx={{
        position: 'relative',
        width: '100%',
        height: 'auto',
        maxHeight: chartHeight,
        aspectRatio: '16/9',
        backgroundColor: 'chart',
        border: !fullscreen ? '1px solid' : '',
        borderColor: 'green.800',
        overflow: 'hidden'
      }}
    >
      <HStack className={'zoom-controls'} display={controls ? 'flex' : 'none'}
        position={'absolute'}
        left={'50%'}
        bottom={'md'}
        transform={'translateX(-50%)'}
        zIndex={'100'}
      >
        <ZoomButton
          className='zoom-controls__button zoom-out'
          label={`Zoom Out`}
          icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-minus" />}
          active={zoomOutTest}
          onClick={zoomOut}
        />
        <ZoomButton
          className='zoom-controls__button zoom-in'
          label={'Zoom In'}
          icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-plus" />}
          active={zoomInTest}
          onClick={zoomIn}
        />
      </HStack>
      {market && (zoom === 0 || zoom) &&
        <ChartFromBinaryWithRef
          ref={chartRef}
          chartHeight={chartHeight}
          handleOnLoad={handleOnLoad}
          setChartBlob={setChartBlob}
          {...props}
        />
      }  
    </Box>
  );
}
