//Dependencies
import {
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

//Chakra
import { Box } from '@chakra-ui/react';

//Providers
import { useView } from '../providers/view';
import { ChartProvider } from '../providers/chart';
import { useMarkets } from '../providers/markets';

//Views
import Single from './single';
import Full from './full';
import Thumbnail from './thumbnail';
import ThumbnailSmall from './thumbnail-small';

//Helpers
import { getMarketByTitle } from '../helpers/utils';


const CurrentView = ({market, view, tags}) => {
  const getView = (view) => {
    switch(view) {
      case 'thumbnail-small':
        return (<ThumbnailSmall />);
      case 'full':
        return (<Full />);
      case 'single':
        return (<Single />);
      default:
        return (<Thumbnail />);
    }
  }

  return (
    <ChartProvider values={{market: market, tags: tags, hasSearchParams: (view === 'single' ? true : false)}}>
      {getView(view)}
    </ChartProvider>
  )
}

export default function Views({ current = null, renderView}) {
  const {markets} = useMarkets();
  const {activeGridView, isSingleView, setIsSingleView, activeTags} = useView();
  const {market} = useParams();

  //Get current market for single view
  const activeMarket = useMemo(() => {
    if(current) {
      return current;
    } else {
      let active = getMarketByTitle(markets, market);
      return active;
    }
  }, [current, market, markets]);

  useEffect(() => {
    if(renderView === 'single') {
      setIsSingleView(true);
    } else {
      setIsSingleView(false);
    }
  }, [renderView, setIsSingleView]);
 
  return (
    <>
    {markets && activeMarket &&
      <Box
        className={'chart-container'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: activeGridView.view === 'full' || isSingleView === 'single' ? 'center' : 'normal',
          height: 'auto',
          width: '100%',
          mx: 'auto',
        }}
      >
        <CurrentView market={activeMarket} view={renderView ? renderView : activeGridView.view} tags={activeTags} />
      </Box>
    }
    </>
  );
}