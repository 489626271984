//Dependencies
import {
  cloneElement,
  useEffect,
  useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';

//Providers
import { useChart } from '../providers/chart';
import { useView } from '../providers/view';



export default function Fullscreen({children, ...props}) {
  const {setFullscreen} = useChart();
  const {containerRef} = useView();
  const [gridRef, setGridRef] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    onOpen();
    setFullscreen(true);
  }

  const handleClose = () => {
    onClose();
    setFullscreen(false);
  }

  const styles = {
    position: 'absolute',
    top: 2,
    right: 2,
    opacity: 0.5,
    zIndex: 'modal',
    '&:hover, &:focus': {
      opacity: 1,
      bgColor: 'green.300'
    }
  }

  useEffect(() => {
    setGridRef(containerRef);
  }, [containerRef]);

  return (
    <>
      {cloneElement(props.open, {onClick: handleClick, className: 'fullscreen', title:"Maximize Chart"})}
      <Modal size={'full'} isOpen={isOpen} onClose={handleClose} finalFocusRef={gridRef}>
        <ModalOverlay />
        <ModalContent borderRadius={0}>
          <Button onClick={handleClose} sx={styles} size={'md'}><FontAwesomeIcon icon="fa-solid fa-times" size="2xl" /></Button>
          <ModalBody p={0} height={'100vh'} width={'auto'} display={'flex'} alignItems={'center'}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
