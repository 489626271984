//Dependencies
import { useState, useEffect, useContext, useMemo, useRef, createContext } from 'react';
import { useSearchParams } from 'react-router-dom';

//Providers
import { useWaveFinder } from '../providers/wave-finder';
import { useUser } from '../providers/user';

//Helpers
import { load } from '../helpers/bchart';
import { createMarketURLBinaryTokenized } from '../helpers/utils';


const ChartContext = createContext();

function useChart() {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error(`useChart must be used within a ChartProvider`);
  }

  return context;
}

function ChartProvider({values: {market, tags, hasSearchParams}, ...props}) {
  const {query} = useWaveFinder();
  const {user} = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamZoom = useMemo(() => parseInt(searchParams.get('z')), [searchParams]); //if z doesn't exist then NaN is returned
  const [zoom, setZoom] = useState(Number.isInteger(searchParamZoom) ? searchParamZoom : null); //used to sync zoom between chart components (NOTE: Not persistent navigating from Single -> Charts - Requires refractor)
  const [width, setWidth] = useState(null); //used to adjust single and full views
  const [fullscreen, setFullscreen] = useState(false); //monitors when fullscreen is active
  const [chartBlob, setChartBlob] = useState(null);
  const [loaded, setLoaded] = useState(false);

  //Necessary for creating bchart
  const [ bchart, setBchart ] = useState(null);

  // Ref to track if zoom change is URL triggered
  const didUpdateZoom = useRef(false);

  const value = useMemo(() => ({
    zoom, setZoom,
    width, setWidth,
    fullscreen, setFullscreen,
    chartBlob, setChartBlob,
    loaded, setLoaded,
    market,
    tags,
    bchart,
    hasSearchParams
  }), [zoom, width, market, tags, hasSearchParams, fullscreen, bchart, chartBlob, loaded]);

  //On chart provider's first render, we'll setup what zoom should be
  useEffect(() => {
    const getWaveOrDefaultZoom = (obj) => {
      if((typeof query === 'object' && Object.keys(query).length > 0)) { //Wave Query has active
        return obj.WaveZoom;
      } else {
        return obj.DefaultZoom;
      }
    }

    if(hasSearchParams) {//Loading single view either from internal link or directly via URL
      if(searchParamZoom < 0) { //Can't be lower than zoom level 0
        setZoom(0);
      } else if(searchParamZoom > (market.ZoomCount - 1)) { //Can't be higher than zoom limit
        setZoom(parseInt(market.ZoomCount) - 1);
      } else {
        setZoom(searchParamZoom);
      }
      didUpdateZoom.current = true;
    } else {
      setZoom(getWaveOrDefaultZoom(market));
    }
  }, [query, hasSearchParams, searchParamZoom, market]);

  //Apply zoom updates based on changes to UI
  useEffect(() => {
    if(hasSearchParams  && !didUpdateZoom.current) {//Loading single view either from internal link or directly via URL
      setSearchParams({z: zoom}, {replace: true}); //sets zoom in url to match zoom in chart when changed via zoom buttons
    }
    didUpdateZoom.current = false;
  }, [hasSearchParams, setSearchParams, zoom]);

  useEffect(() => {
    const getBinaryChartAsync = async (cleanup) => {
      const path = createMarketURLBinaryTokenized(market?.Token, user.package);
      try {
        if(path) {
          const object = await load(path, cleanup);
          setBchart(object)
        }
      } catch (error) {
        console.error('Error loading binary path', error);
      }
    }

    const cleanup = new AbortController();

    if(market?.Token && user?.package) {
      getBinaryChartAsync(cleanup);

      return () => {
        cleanup.abort();
      }
    }
  }, [market?.Token, user?.package]);

  // useEffect(() => console.log('TOKEN', market?.Token), [market?.Token]);


  return <ChartContext.Provider value={value} {...props} />
}

export {ChartProvider, useChart};