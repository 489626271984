//Dependencies
import { useState, useRef, useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import debounce from "lodash.debounce";

//Components
import { Panel, BackToTop } from '../../components';


export default function Main() {
  const ref = useRef(null);
  const [showButton, setShowButton] = useState(false);

  const handleClick = () => {
    ref.current.scrollTo({top: 0, behavior: 'auto'});
  }
  
  const handleBackToTop = debounce(() => {
    if(ref.current.scrollTop > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, 500);
  
  useLayoutEffect(() => {
    const panel = ref.current;

    if(panel) {
      panel.addEventListener('scroll', handleBackToTop);
    }
    return () => {
      if(panel) {
        panel.removeEventListener('scroll', handleBackToTop);
      }
    }
  }, [handleBackToTop, ref]);

  return (
    <Panel ref={ref} className='main has-scroll' py={{base: '4', desktop: '8'}} px={{base: '6', desktop: '8'}} justifyContent={'flex-start'} alignItems={'center'} overflowY={'scroll'}>
      <Outlet />
      <BackToTop state={showButton} fnc={handleClick} />
    </Panel>
  );
}