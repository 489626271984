//Chakra
import {
  Skeleton,
} from '@chakra-ui/react';

//Components
import {
  Chart,
  ChartHeader,
  ChartFooter
 } from "../components";

 //Providers
import { useChart } from '../providers/chart';


export default function Thumbnail() {
  const {loaded} = useChart();

  const skeletonStyles = {
    borderRadius: 'sm',
    overflow: 'hidden'
  }

  return (
    <Skeleton isLoaded={loaded} sx={skeletonStyles}>
      <ChartHeader />
      <Chart />
      <ChartFooter />
    </Skeleton>
  );
}