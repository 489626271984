//Dependencies
import {
  useState,
  useEffect,
} from 'react';

//Helpers
import { to_svg } from '../helpers/bchart';

//Providers
import { useChart } from '../providers/chart';


export default function useBinaryChart() {
  const {
    market,
    zoom,
    bchart,
  } = useChart();

  const [ chartHTML, setChartHTML ] = useState(null);

  useEffect(() => {
    if(bchart && (market?.Title !== bchart?.symbol)) { //Make sure market and bchart are the same! 
      console.warn(`Market data mismatch! Bchart was expecting ${bchart?.symbol}, but found ${market?.Title}.`);
      return;
    }

    if(bchart && (zoom === 0 || zoom)) {
      setChartHTML(to_svg(bchart, zoom));
    }
    
    // console.log('BChart', bchart, market);
  }, [bchart, zoom, market]);

  return {
    bchart,
    chartHTML,
  };
}