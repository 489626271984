//Dependencies
import { useCallback, useContext, useMemo, createContext, useReducer } from 'react';
import uniqid from 'uniqid';

//Providers
import { useWaveFinder } from '../providers/wave-finder';
import { useUser } from '../providers/user';


const QueryContext = createContext();

//Default values for when query is reset
const prefixCont = 'c-';
const prefixAttr = 'a-';

//Query Reducer setups all functionality for controlling state object (Note: State is referred to as "contexts")
function queryReducer(contexts, action) {
  switch(action.type) {
    case 'ADD_CONTEXT': {
      return [...contexts, {
        id: uniqid(prefixCont, `-${action.value}`),
        name: action.value,
        attributes: [{id: uniqid(prefixAttr), name: null, values: null}]
      }];
    }
    case 'UPDATE_CONTEXT': {
      return contexts.map(context => {
        if(context.id === action.id) {
          context.name = action.value;
          return context;
        } else {
          return context;
        }
      })
    }
    case 'DELETE_CONTEXT': {
      return contexts.filter(context => context.id !== action.id);
    }
    case 'CLEAR_STATE': {
      return [];
    }
    case 'REPLACE_STATE': {
      return action.value;
    }
    case 'ADD_ATTRIBUTE': {
      //Get the index of the context we're going to update
      const currContextIndex = contexts.findIndex(context => context.id === action.id);
      //Add a new default attribute to the attributes array for the context
      const updatedContextWithNewAttrArray = {...contexts[currContextIndex], attributes: [...contexts[currContextIndex].attributes, {id: uniqid(prefixAttr), name: null, values: null}]};
      //Return a new array with the updated context
      return [
        ...contexts.slice(0, currContextIndex),
        updatedContextWithNewAttrArray,
        ...contexts.slice(currContextIndex + 1)
      ];
    }
    case 'UPDATE_ATTRIBUTE': {
      return contexts.map(context => {
        if(context.id === action.contextId) {
          //If matching context then update attributes with new attributes array containing updated value
          context.attributes = [...context.attributes].map(attribute => {
            if(attribute.id === action.id) {
              //If matching attribute then update name and return
              attribute.name = action.name ? action.name : attribute.name;
              attribute.values = action.values ? action.values : attribute.values;
              return attribute;
            }
            return attribute;
          });
          return context;
        }
        return context;
      });
    }
    case 'DELETE_ATTRIBUTE': {
      //Get the index of the context we're going to update
      const currContextIndex = contexts.findIndex(context => context.id === action.contextId);
      //Find attributes for correct context using index and copy current attributes minus the deleted one
      const updatedContextAttributes = {...contexts[currContextIndex], attributes: [...contexts[currContextIndex].attributes].filter(attribute => attribute.id !== action.id)}
      //Return a new array with the updated context
      return [
        ...contexts.slice(0, currContextIndex),
        updatedContextAttributes,
        ...contexts.slice(currContextIndex + 1)
      ];
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

function QueryProvider(props) {
  const {queryInfo} = useWaveFinder();
  const {user} = useUser();

  //Grab attribute info from query info and format it to use for input generation
  const fields = useMemo(() => {
    if(queryInfo) {
      return Object.entries(queryInfo.attributes).map(([key, value]) => {
        if(value.type === 'list') {
          return {name: key, type: value.type, options: value.values}
        }
        if(value.type === 'range') {
          return {name: key, type: value.type, units: value.units, min: value.min, max: value.max}
        }
        return console.error('Unknown attribute type specified in query information.');
      });
    }
    return;
  }, [queryInfo]);

  //Check the query returned from the user object to make sure saved values are still valid and won't crash UI
  const validateQueryFromUser = useCallback((userQuery) => {
    if(queryInfo && userQuery?.length) {
      //Setup reference values to check against
      const waveRef = queryInfo.waves;
      const fieldsRef = fields;
      const valuesListRef = fields.filter(field => field.type === 'list');
      
      //Check all context waves to ensure their of the correct type
      let validUserQuery = userQuery.filter(context => waveRef.some(ref => ref === context.name));
      
      //Check all attributes in validated context wave to make sure they're a correct type
      validUserQuery = [...validUserQuery].map(context => ({
        ...context,
        attributes: context.attributes.filter(attr => fieldsRef.some(ref => ref.name === attr.name)),
      }));

      //Check all the values inside validated attrs to make sure they're allowed as well
      validUserQuery = [...validUserQuery].map(context => {
        context.attributes = context.attributes.map(attribute => {
          if(Array.isArray(attribute.values)) {
            //If array of values, filter invalid values out and return the remaining
            attribute.values = attribute.values.filter(value => {
              const currListRef = valuesListRef.filter(ref => attribute.name === ref.name).pop(); //get the correct ref values for list type
              return currListRef.options.some(ref => ref === value);
            });
          }
          return attribute;
        });
        return context;
      });

      return validUserQuery
    }

    console.warn('User query not validated because of error with reference and/or retrieving user query.');
    return [];
  }, [queryInfo, fields]);

  const getInitialState = (user) => validateQueryFromUser(user.query);

  const [state, dispatch] = useReducer(queryReducer, user, getInitialState);
  const contextWaves = queryInfo ? queryInfo.waves : null;
  const value = useMemo(() => ([state, dispatch, fields, contextWaves]), [state, fields, contextWaves]);

  return <QueryContext.Provider value={value} {...props} />
}

function useQuery() {
  const context = useContext(QueryContext);
  if (!context) {
    throw new Error(`useQuery must be used within a QueryProvider`);
  }
  const [state, dispatch, fields, contextWaves] = context;

  const addContext = (value = null) => dispatch({
    type: 'ADD_CONTEXT',
    value: value
  });

  const updateContext = (id, value) => dispatch({
    type: 'UPDATE_CONTEXT',
    id: id,
    value: value
  });

  const deleteContext = (id) => dispatch({
    type: 'DELETE_CONTEXT',
    id: id
  });

  const clearState = () => dispatch({
    type: 'CLEAR_STATE'
  });
  
  const replaceState = (value) => dispatch({
    type: 'REPLACE_STATE',
    value: value
  });

  const addAttribute = (id) => dispatch({
    type: 'ADD_ATTRIBUTE',
    id: id
  });

  const updateAttribute = (contextId, id, name = null, values = null) => dispatch({
    type: 'UPDATE_ATTRIBUTE',
    contextId: contextId,
    id: id,
    name: name,
    values: values
  });

  const deleteAttribute = (contextId, id) => dispatch({
    type: 'DELETE_ATTRIBUTE',
    contextId: contextId,
    id: id
  });

  return {
    contextWaves,
    fields,
    state,
    dispatch,
    addContext,
    updateContext,
    deleteContext,
    replaceState,
    clearState,
    addAttribute,
    updateAttribute,
    deleteAttribute
  };
}

export {QueryProvider, useQuery};