//Dependencies
import { useState, useContext, useMemo, createContext } from 'react';


const ViewContext = createContext();

function useView() {
  const context = useContext(ViewContext);
  if (!context) {
    throw new Error(`useView must be used within a ViewProvider`);
  }

  return context;
}

function ViewProvider(props) {
  const defaultItemsPerPage = 10;

  const [containerRef, setContainerRef] = useState(null); //Container of charts page for back to top targeting - Revisit this?
  const [activeTags, setActiveTags] = useState([]);
  const [sort, setSort] = useState(null);  //Determine sort order of results 
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage); //Number of results per page on view
  const [activeGridView, setActiveGridView] = useState({view: null, size: null, typeId: null}); //Current active grid view
  const [isSingleView, setIsSingleView] = useState(false);
  
  const value = useMemo(() => ({
    containerRef, setContainerRef,
    activeTags, setActiveTags,
    sort, setSort,
    itemsPerPage, setItemsPerPage,
    activeGridView, setActiveGridView,
    isSingleView, setIsSingleView,
    defaultItemsPerPage
  }), [containerRef, activeTags, sort, itemsPerPage, activeGridView, isSingleView, defaultItemsPerPage]);
  
  return <ViewContext.Provider value={value} {...props} />
}

export {ViewProvider, useView};