//Dependencies
import { useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  IconButton,
  Stack,
  Heading,
  Box,
  Button,
  useColorMode,
  useBreakpointValue
} from '@chakra-ui/react';

//Providers
import { useView } from '../../providers/view';

//Components
import {
  Panel,
  Regions,
  WaveQuery
} from '../../components';

//Hooks
// import useFilters from '../../hooks/use-filters';


export default function Sidebar({status}) {
  // const {filters} = useMarkets();
  // const filter = useFilters();
  const {activeTags, setActiveTags} = useView();
  const {colorMode} = useColorMode();
  const isScreenDesktop = useBreakpointValue({
    base: false,
    desktop: true
  },
  {
    ssr: false
  });

  const handleResize = () => {
    if(status.check) status.set(false)
  }

  const handleClearFilters = () => {
    setActiveTags([]);
    // if(filters) filter.clearAllFilters(filters);
  }

  useLayoutEffect(() => {
    //set status based on first load of window size
    status.set(isScreenDesktop);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  return (
    <>
      <IconButton sx={{
          position: 'absolute',
          top: {base: '50%', desktop: 4},
          right: '0',
          transform: {base: status.check ? 'translate(70%, -50%)' : 'translate(50%, -50%)', desktop: 'translate(50%)'},
          opacity: status.check ? '0.60' : '1.0',
          borderRadius: 'sm',
          zIndex: '1000',
        }}
        variant='solid'
        aria-label={'Toggle Sidebar'}
        title={'Toggle Sidebar'}
        background={colorMode === 'light' ? 'gray.200' : 'gray.700'}
        icon={status.check ? <FontAwesomeIcon icon='fa-solid fa-sidebar' /> : <FontAwesomeIcon icon='fa-thin fa-sidebar' />}
        onClick={ () => status.check ? status.set(false) : status.set(true) }
        transition={'all 0.2s ease-in-out'}
      />
      <Panel
        className={'sidebar'}
        p={{base: 'sm', sm: 'xs', }}
        justifyContent={'flex-start'}
        alignItems={'center'}
        overflowX={'hidden'}
        overflowY={'show'}
        opacity={ status.check ? '1' : '0'}
        transition={'all 0.3s ease-in-out 0.1s'}
        sx={{scrollbarGutter: "stable"}}
      >
        <Stack w={'100%'} p={'xs'} pb={0} spacing={6} direction='column' opacity={status.check ? '1' : '0'} transition={'all 0.1s ease-in-out'}>
          <Box pos={'relative'} mb={'4'}>
            <Heading size={'md'} pb={'xs'} mb={'xs'} borderBottom={'1px solid'} borderColor={'gray.200'}>Markets</Heading>
            {activeTags.length > 0 && (<Button size={'xs'} pos={'absolute'} top={'100%'} right={'0'} mt={'xs'} color="primary" rightIcon={<FontAwesomeIcon icon="fa-solid fa-trash-xmark" />} onClick={handleClearFilters}>Clear Filters</Button>)}
            <Regions />
          </Box>
          <WaveQuery />
        </Stack>
      </Panel>
    </>
  );
}