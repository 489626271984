import { useQuery } from "../providers/query";
import uniqid from 'uniqid';

// {
//   "name": "ending diagonal setup",
//   "query": {
//       "wave": {
//           "Duration (Expected)": {
//               "min": 14,
//               "max": 1000
//           },
//           "break": [
//               "4 of prior ending diagonal"
//           ],
//           "container": [
//               "prior ending diagonal"
//           ],
//           "subtype": [
//               "post ending diagonal"
//           ]
//       }
//   }
// }

//Default values for when query is reset
const prefixCont = 'c-';
const prefixAttr = 'a-';

export default function useConverter() {
  const {state, replaceContext} = useQuery();

  //Helper function takes wave query objects and converts them into state arrays for the user interface
  const convertQueryToState = ({name, query}) => {
    return Object.entries(query).map(([cKey, cValue]) => {
      return ({
        "template_name": name,
        "name": cKey,
        "id": uniqid(prefixCont, `-${cKey}`),
        "attributes": Object.entries(cValue).map(([aKey, aValue]) => {
          return ({
            "id": uniqid(prefixAttr, `-${aKey}`),
            "name": aKey,
            "values": aValue,
          })
        }),
      });
    });
  }

  //Helper function takes state arrays of objects and converts them into a multidimensional object that the Wave Finder can use
  const convertStateToQuery = (state) => {
    const isNotEmpty = (obj) => {
      return Array.isArray(obj) ? obj.length > 0 : 
            (typeof obj === 'object' && Object.keys(obj).length > 0);
    }
    const newQuery = {};

    state.filter(context => context.name).forEach(context => {
      if(context.name) {
        newQuery[context.name] = {};
        if(context.attributes) {
          context.attributes.forEach(attribute => {
            if(attribute.name) {
              if(attribute.values && isNotEmpty(attribute.values)) {
                newQuery[context.name][attribute.name] = attribute.values;
              }
            }
          });
        }
      }
    });
    
    return newQuery;
  };

  return {
    convertQueryToState: convertQueryToState,
    convertStateToQuery: convertStateToQuery,
  };
}
