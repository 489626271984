//Dependencies
import {
  useEffect,
  useState,
  useMemo,
  useRef
} from 'react';
import {
  useParams,
  useNavigate,
  Outlet
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  Box,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';

//Providers
import { useMarkets } from '../providers/markets';
import { useView } from '../providers/view';

//Components
import { ChartControls, ChartInfo, ChartPagination } from '../components';
import { useWaveFinder } from '../providers/wave-finder';


export default function Charts() {
  const {waves, maxWaves} = useWaveFinder();
  const {setContainerRef} = useView();
  const {markets, setShowSearch} = useMarkets();
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
    setContainerRef(ref);
  }, []);

  useEffect(() => {
    setShowSearch(true);
  }, [markets]);

  useEffect(() => {
    if(waves) setLoaded(true);
  }, [waves]);

  return (
    <>
      { !params?.market
        ?
        <Flex ref={ref} className={'charts-container'}>
          <Box width={'100%'} textAlign={'left'}>
            <Button size='sm' color={'primary'} variant={'link'} alignItems={'baseline'} aria-label='Go back' bgColor={'transparent'} leftIcon={<FontAwesomeIcon icon="fa-solid fa-angle-left" />} onClick={() => {navigate(-1)}}>BACK</Button>
          </Box>
          <Flex mb={{base: 'xs', md: 'sm', xl: 'md'}} width={'100%'} flexDir={{base: 'column', md: 'row'}} alignItems={'flex-start'} justifyContent={'space-between'} scrollSnapAlign={'center'}>
            <ChartInfo numOfResults={maxWaves} />
            <ChartControls />
          </Flex>
          {waves?.length
            ?
              <ChartPagination />
            :
              <Box w={'auto'} textAlign={'center'}>
                {loaded
                  ?
                    <Text fontSize={'2xl'}>No results found.<br/>Please consider revising your filter options.</Text>
                  :
                    <Text fontSize={'2xl'}>Loading Results...</Text>
                }
              </Box>
          }
        </Flex>
        :
        <Outlet />
      }
    </>
  );
}