//Dependencies
import {
  useMemo,
  useEffect
} from 'react';
import isEqual from 'lodash.isequal';

//Chakra
import {
  Text,
  Flex,
} from '@chakra-ui/react';

//Components
import {
  // ChartTags,
} from '../components';

//Providers
import { useWaveFinder } from '../providers/wave-finder';
import { useChart } from '../providers/chart';

//Helpers
import { getMarketByWaves, toTitleCase } from '../helpers/utils'; 


export default function ChartFooter() {
  const {
    market,
    // tags
  } = useChart();
  const { sortBy, waves, queryInfo } = useWaveFinder();

  const currentWave = useMemo(() => {
    if(waves) return getMarketByWaves(market, waves)
  }, [market, waves]);

  const sortInfo = useMemo(() => {
    let value;
    if(currentWave && !isEqual(currentWave, [])) {
      value = currentWave[sortBy];
      let units = queryInfo.attributes[sortBy].units;
      return (`${toTitleCase(sortBy)}: ${Math.round(value)} ${units}`);
    }
    return null;
  }, [currentWave, sortBy, queryInfo]);

  const defaultSortInfo = useMemo(() => {
    let units = queryInfo.attributes['elliotticity'].units;
    return (`${toTitleCase('Elliotticity')}: ${Math.round(currentWave?.elliotticity ? currentWave?.elliotticity : market?.Elliotticity)} ${units}`);
  }, [queryInfo, market, currentWave]);

  return (
    <>
      <Flex flexDir={'column'} alignItems={'flex-end'} mt={'xs'} mb={'xs'} width={'100%'}>
        {sortBy !== 'elliotticity' && sortInfo !== null &&
          <Text fontSize={'xs'} fontWeight={'400'}>({defaultSortInfo})</Text>
        }
        {sortInfo
          ?
          <Text fontSize={'sm'} fontWeight={'700'}>{sortInfo}</Text>
          :
          <Text fontSize={'sm'} fontWeight={'700'}>{defaultSortInfo}</Text>
        }
      </Flex>
      {/* {tags.length > 0 && <ChartTags market={market} isFiltered={true} variant={'badge'} />} */}
    </>
  )
}