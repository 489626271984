//Chakra
import {
  Skeleton,
} from '@chakra-ui/react';

//Components
import {
  Chart,
  ChartHeader,
  ChartFooter
} from '../components';

//Providers
import { useChart } from '../providers/chart';


export default function Full({hasHeader = true, hasFooter = true}) {
  const { loaded } = useChart();
   
  const skeletonStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 'sm',
    overflow: 'hidden',
    marginInline: 'auto',
  }
 
  return (
    <Skeleton
      isLoaded={loaded}
      w='100%'
      sx={skeletonStyles}
    >
      {hasHeader && <ChartHeader />}
      <Chart />
      {hasFooter && <ChartFooter />}
    </Skeleton>
  );
}