//Dependencies
import {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo
} from "react";
import debounce from "lodash.debounce";
import isEqual from "lodash.isequal";
import difference from "lodash.difference";
import { useNavigate, useLocation } from "react-router-dom";

//Chakra
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  VStack,
  IconButton,
  Text,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Wrap,
  WrapItem,
  HStack,
  Switch,
  useToken,
  useDisclosure
} from "@chakra-ui/react";

//Providers
import { useWaveFinder } from "../providers/wave-finder";
import { useQuery } from "../providers/query";
import { useUser } from "../providers/user";
import { useAuthentication } from "../providers/authentication";
import { useView } from "../providers/view";

//Helpers
import { useConvertor } from "../hooks";
import { toTitleCase } from "../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setFBUser } from "../helpers/firebase";
import { serverTimestamp } from "firebase/firestore";



//List Input represents 1st type of attribute and has an array of string values. Can have multiple values selected at once, but only one of each given value.
const ListInput = ({contextId, id, name, fields, values}) => {
  const {updateAttribute} = useQuery();
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(values ? values : []);

  //Format fields to find only options for this name
  const options = useMemo(() => {
    const field = [...fields].filter(field => field.name === name).pop();
    return field.options;
  }, [name, fields]);
  //Available options are only the ones not currently selected
  const availableOptions = useMemo(() => {
    return difference(options, selected);
  }, [options, selected]);

  //Update component state and query state when new values selected or removed
  const handleSelectValues = (e) => {
    const newSelected = [...selected, e.target.value];

    updateAttribute(contextId, id, null, newSelected);
    setSelected(newSelected);

    //Ensures that the select field shows "Add More Types".
    //Otherwise the select field would show last type selected. (Should probably use different DOM / elements structure in future)
    setValue('');
  }
  const handleDeleteValues = (item) => {
    const newSelected = selected.filter(select => select !== item);

    updateAttribute(contextId, id, null, newSelected);
    setSelected(newSelected);
  }

  return (
    <VStack alignItems={'flex-start'}>
      {selected?.length &&
        <Wrap>
          <Heading size={'xs'} color={'gray.600'} lineHeight={'inherit'}>{toTitleCase(name)}s:</Heading>
          {selected.map((item, key) => (
            <WrapItem key={item+key}>
              <Button size={'xs'} bgColor={'primary'} color={'white'} _hover={{bgColor: 'red'}} onClick={() => handleDeleteValues(item)}>{item}</Button>
            </WrapItem>
          ))}
        </Wrap>
      }
      <Select variant={'lightPrimary'} size={selected?.length > 0 ? 'xs' : 'sm'} fontWeight={'medium'} width={'max-content'} placeholder={`Add ${selected?.length > 0 ? 'More' : ''} ${toTitleCase(name)}s`} value={value} onChange={handleSelectValues}>
        {availableOptions.map((option, key) =>
          <option key={key} value={option}>{option}</option>
        )}
      </Select>
    </VStack>
  )
}

//Range Input represents 2nd type of attribute and has a min and max range and displays the type of units being passed. Can potentially have an infinite min or max range.
const RangeInput = ({contextId, id, name, fields, values}) => {
  const {updateAttribute} = useQuery();

  //Format fields to find only options for this name
  const range = useMemo(() => [...fields].filter(field => field.name === name).pop(), [name, fields]);

  //State for min and max values (Values are null for new attributes.)
  const [min, setMin] = useState(values && values.min !== null && Number.isInteger(values.min) ? values.min : range.min);
  const [max, setMax] = useState(values && values.max !== null && Number.isInteger(values.max) ? values.max : range.max);

  //Debounced inputs
  const handleChangeMin = (valueAsNumber) => setMin(valueAsNumber);
  const handleChangeMax = (valueAsNumber) => setMax(valueAsNumber);
  const debouncedChangeMin = debounce(handleChangeMin, 500);
  const debouncedChangeMax = debounce(handleChangeMax, 500);

  useEffect(() => {
    const newValues = {min: parseFloat(min), max: parseFloat(max)};
    updateAttribute(contextId, id, null, newValues);
  }, [min, max]);

  return (
    <VStack alignItems={'flex-start'} spacing={1}>
      <Heading size={'xs'} color={'gray.600'} lineHeight={'inherit'}>{toTitleCase(name)}:</Heading>
      <HStack>
        <NumberInput size='xs' w={'45%'} defaultValue={min} min={range.min} max={range.max} onChange={debouncedChangeMin}>
          <NumberInputField textAlign={'center'} background={'white'}/>
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text w={'10%'} textAlign={'center'}>to</Text>
        <NumberInput size='xs' w={'45%'} defaultValue={max} min={range.min} max={range.max} onChange={debouncedChangeMax}>
          <NumberInputField textAlign={'center'} background={'white'} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </HStack>
      <Text fontSize={'xs'} fontWeight={'700'} color={'gray.600'}>in {range.units}</Text>
    </VStack>
  )
}

const Attribute = ({contextId, id, name, values, available}) => {
  const {fields, updateAttribute} = useQuery();

  //Available options are only the ones not currently selected
  const availableFields = useMemo(() => {
    const attributes = [...fields].filter(field => available.some(val => field.name === val));
    return difference(fields, attributes);
  }, [fields, available]);

  //Based on name, return the fields format type
  const format = useMemo(() => {
    if(name && fields) {
      const currField = fields.filter(field => field.name === name).pop();
      return currField?.type;
    }
    return;
  }, [name, fields]);

  const handleOnChange = (e) => {
    updateAttribute(contextId, id, e.target.value);
  }
  
  switch (format) {
    case 'list':
      return <ListInput contextId={contextId} id={id} name={name} fields={fields} values={values} />
    case 'range':
      return <RangeInput contextId={contextId} id={id} name={name} fields={fields} values={values} />
    default:
      return (
        <Select variant={'lightPrimary'} size={'sm'} placeholder={`Select Attribute`} onChange={handleOnChange}>
          {availableFields && availableFields.map((field, key) =>
            <option key={field.name+key} value={field.name}>{toTitleCase(field.name)}</option>
          )}
        </Select>
      )
  }
}

const ContextAttributes = ({id}) => {
  const {state, addAttribute, deleteAttribute} = useQuery();
  const context = useMemo(() => state.filter(context => context.id === id).pop(), [state, id]);
  
  const selectedAttributes = useMemo(() => {
    const selected = [...context.attributes].map(attribute => attribute.name);
    return selected;
  }, [context.attributes]);

  return (
    <VStack alignItems={'flex-start'} width={'100%'} spacing={3}>
      {context.attributes.map((attribute, i) => (
        <HStack key={attribute.name+i} bgColor={'gray.200'} position={'relative'} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'} p={'xs'} pr={'sm'} borderWidth={'0px'} borderColor={'gray.300'} borderRadius={'sm'}>
          <Box position={'absolute'} width={'10px'} height={'2px'} top={'50%'} left={'-10px'} bgColor={'gray.300'} />
          <Attribute contextId={context.id} id={attribute.id} name={attribute.name} values={attribute.values} available={selectedAttributes} />
          <IconButton position={'absolute'} transform={'translate(50%, -50%)'} top={'50%'} right={0} aria-label='Remove Attribute' borderRadius={'full'} size={'xs'} variant={'outline'} colorScheme={'red'} backgroundColor={'white'} icon={<FontAwesomeIcon icon="fa-solid fa-times" />} onClick={() => deleteAttribute(context.id, attribute.id)} />
        </HStack>
      ))}
      <Button variant={'primary'} size={'xs'} color={'gray.500'} borderColor={'gray.300'} _hover={{bgColor:'primary', borderColor: 'primary', color: 'white'}}
        leftIcon={<FontAwesomeIcon icon="fa-solid fa-plus" />}
        onClick={() => addAttribute(context.id)}
      >Add Attribute</Button>
    </VStack>
  )
  
}

//Sets up initial state of query and formats query info data to be handled by children components. Holds state and elements for creating multiple context waves which make up the query
const WaveQuery = () => {
  const defaultSelected = ['wave'];
  const navigate = useNavigate();
  const location = useLocation();

  const {authentication} = useAuthentication();
  const {user, updateFBUserPref} = useUser();
  const {isSingleView} = useView();
  const {setQuery, setPageNumber, queryInfo, templates, setWfActive} = useWaveFinder();
  const {state, addContext, updateContext, deleteContext, clearState, replaceState} = useQuery();
  const savedContexts = useMemo(() => [...state].map(item => item.name), [state]);

  const [enabled, setEnabled] = useState(user && user.queryToggle ? user.queryToggle : false);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(savedContexts ? savedContexts : []);
  const [lastQuery, setLastQuery] = useState({});
  const [templateModified, setTemplateModified] = useState(false);
  const [curTemplate, setCurTemplate] = useState(false);

  const availableContexts = useMemo(() => difference(queryInfo?.waves, selected), [queryInfo, selected]);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {convertQueryToState, convertStateToQuery} = useConvertor();
  const cancelRef = useRef();

  const saveStateToFBUser = useCallback(async () => {
    try {
      await setFBUser(authentication.uid, { query: state, queryTimestamp: serverTimestamp() });
    } catch (e) {
      return console.error(e);
    }
  }, [state, authentication.uid]);

  const [gray300] = useToken(
    "colors",
    ["gray.300"]
  );

  const borderBefore = {
    position: 'absolute',
    background: 'gray.300',
    top: '1rem',
    left: '-2px',
    width: '2px',
    height: 'calc(100% - 1.625rem)',
  }

  //Create new query and auto add a new context for "wave"
  const handleNewQuery = () => {
    addContext('wave');
    setSelected(defaultSelected);
  }

  //Clear out query state and reset WaveQuery to [] state
  const handleReset = () => {
    clearState();
    setCurTemplate(false);
    setTemplateModified(false);
    handleNewQuery();
    onClose();
  }

  //Updates the specific empty context to have a name and show the context attributes component instead. All selected context(wave) values are excluded from the select list
  const handleUpdateContextType = (id, value) => {
    updateContext(id, value);
    setSelected([...selected, value]);
  }

  const handleAddContext = () => {
    addContext();
  }

  //Delete a context and update selected state array
  const handleRemoveContext = (id, name) => {
    deleteContext(id);
    setSelected(selected.filter(select => select !== name));
  }

  const handleToggle = (e) => {
    const enabled = e.target.checked;
    if(!enabled) {
      setQuery({});
      updateFBUserPref.queryToggle(false);
    } else {
      setQuery(lastQuery);
      updateFBUserPref.queryToggle(true);
      if(!isSingleView && location.pathname !== '/charts') navigate('/charts');
    }
    setEnabled(enabled);
  }
  const debouncedHandleToggle = debounce((e) => handleToggle(e), 1000, {leading: true});

  //Loads a predefined template when user selects from list of options, converts query and then replaces state
  const handleTemplateSelect = (e) => {
    if(e.target.value === false || e.target.value === 'false') {
      return;
    }

    const selected = templates[e.target.value];
    const newState = convertQueryToState(selected);
    const contexts = [...newState].map(item => item.name)

    replaceState(newState);
    setSelected(contexts);
    setCurTemplate(e.target.value);
    setTemplateModified(false);
  }

  //On initial render, get the saved query state from Firestore db
  useEffect(() => {
    if(!enabled) {
      setQuery({});
    } else {
      setQuery(lastQuery);
    }
    setLoading(false);

    return () => {
      if(!state.length) handleNewQuery();
    }
  }, []);

  //Each time the query state is updated, update the saved state in Firestore db and update wave query
  useEffect(() => {
    //***** Post-MVP: This should be smarter and only save state for items that aren't null or new empty values. *****
    if(state && state.length) {
      if(enabled) {
        const newQuery = convertStateToQuery(state);

        if(!isEqual(lastQuery, newQuery)) {
          setPageNumber(0);
          setLastQuery(newQuery);
          setQuery(newQuery);
          saveStateToFBUser();
        }
      }
    }
  }, [state, lastQuery, enabled]);

  //Monitors loaded templates and adds a notification icon if it's modified from original state
  useEffect(() => {
    if(curTemplate) { //make sure current template is set to actual index number
      const templateQuery = templates[curTemplate].query;
      const currentQuery = convertStateToQuery(state);

      if(!isEqual(currentQuery, templateQuery)) {
        setTemplateModified(true);
      }
    }
  }, [state, templates, curTemplate]);

  useEffect(() => setWfActive(enabled), [enabled]);

  // useEffect(() => console.log('Available!', availableContexts), [availableContexts]);

  return (
    <>
      <Box pos={'relative'} mb={'md'} display={'flex'} flexDir={'column'} zIndex={'docked'}>
        <Heading size={'md'} pb={'xs'} mb={'sm'} borderBottom={'1px solid'} borderColor={'gray.200'}>Wave Finder</Heading>
        <Switch className={'query-toggle'} position={'absolute'} top={'0.25rem'} right={'0'} colorScheme={'green'} isChecked={enabled} onChange={debouncedHandleToggle} />
        <Box className={'templates-wrapper'} mb={'xs'} sx={!enabled ? {pointerEvents: 'none', opacity: '0.5'} : {}}>
          <Heading size={'xs'} pb={'xs'} mb={'0'} color={'gray.600'}>
            <FontAwesomeIcon icon="fa-sharp fa-solid fa-diagram-subtask" /> Templates {templateModified  && <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />}
          </Heading>
          <Select value={curTemplate} size={'small'} color={'gray.500'} variant={'lightPrimary'} onChange={handleTemplateSelect}>
            <option key={'please select'} value={false}>Select setup</option>
            {templates?.map((template, index) => (
              <option key={index+template.name} value={index}>{toTitleCase(template.name)}</option>
            ))}
          </Select>
        </Box>
        { loading 
          ?
            <Text>Loading...</Text>
          :
            <VStack alignItems={'flex-start'} spacing={2} w={'100%'}>
              <VStack className={'context-wrapper'} position={'relative'} alignItems={'flex-start'} width={'100%'} spacing={'md'} py={'sm'} pr={'3'} sx={!enabled ? {pointerEvents: 'none', opacity: '0.5'} : {}}>
                {state && state.map((context, i) => (
                  <Box
                    key={context.name+i}
                    width={'100%'}
                    position={'relative'}
                    pl={'xs'}
                    backgroundImage={`linear-gradient(${gray300}, ${gray300}), linear-gradient(${gray300}, ${gray300})`}
                    backgroundRepeat={'no-repeat'}
                    backgroundSize={'10px 2px'}
                    backgroundPosition={'0% calc(0% + 1rem), 0% calc(100% - (1.5rem / 2) + 2px)'}
                  >
                    <Box sx={borderBefore} />
                    <>
                      <HStack position={'relative'} justifyContent={'flex-start'} mb={'xs'} py={'xs'} pl={'sm'} pr={'md'} width={'fit-content'} backgroundColor={'gray.200'} color={'primary'} borderRadius={'sm'}>
                        <Heading size={'sm'}>{context.name ? toTitleCase(context.name) : 'New Context'}</Heading>
                        <IconButton position={'absolute'} transform={'translate(50%, -50%)'} top={'50%'} right={0} aria-label='Remove Context' borderRadius={'full'} size={'xs'} variant={'outline'} colorScheme={'red'} backgroundColor={'white'}
                          icon={<FontAwesomeIcon icon="fa-solid fa-times" />}
                          onClick={() => handleRemoveContext(context.id, context.name)}
                        />
                      </HStack>
                      <>
                        {context?.name
                          ? 
                            <ContextAttributes key={context.id+i} id={context.id} />
                          :
                            <Select size={'standard'} variant={'lightPrimary'} placeholder='Select Wave' onChange={(e) => handleUpdateContextType(context.id, e.target.value)}>
                              {availableContexts?.map((context, key) =>
                                <option key={context+key} value={context}>{toTitleCase(context)}</option>
                              )}
                            </Select>
                        }
                      </>
                    </>
                  </Box>
                ))}
                <Button variant={'primary'} size={'sm'} color={'gray.500'} borderColor={'gray.300'} _hover={{bgColor:'primary', borderColor: 'primary', color: 'white'}}
                  leftIcon={<FontAwesomeIcon icon="fa-solid fa-plus" />}
                  onClick={handleAddContext}
                >Add Context Wave</Button>
              </VStack>
            </VStack>
        }
          {!loading && enabled && state.length > 0 &&
          <Button alignSelf={'end'} size={'xs'} mt={'xs'} colorScheme={'red'} rightIcon={<FontAwesomeIcon icon="fa-solid fa-trash-xmark" />} onClick={onOpen}>Reset Query</Button>
        }
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Reset Query
              </AlertDialogHeader>

              <AlertDialogBody bgColor={'transparent'}>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={handleReset} ml={3}>
                  Reset
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </>
  )
}

export default WaveQuery;