//Dependencies
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  Box,
  Button,
  Stack,
  Heading,
  Flex,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

//Providers
import { useMarkets } from '../providers/markets';

//Components
import { Logo, Search } from '../components';


//Home is what is initially seen when loading the app or otherwise known as the home / dashboard screen
const Home = () => {
  const {setShowSearch} = useMarkets();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('charts');
  }

  //Search in header should be hidden on home page
  useEffect(() => {  
    setShowSearch(false); // eslint-disable-next-line
  }, []);


  return (
    <>
    <Flex height={'100%'} className='default' alignItems={'center'}>
      <Stack maxW='600px' direction='column' p={{base: '10vw', desktop: '20'}} bgColor={useColorModeValue('whiteAlpha.700', 'blackAlpha.700')} backdropFilter='auto' backdropBlur={'2px'} textAlign={'center'} alignItems={'center'}>
        <Stack pb={'lg'} direction={{base: 'column', desktop: 'row'}} alignItems={'center'} justifyContent={'center'}>
          <Heading size={'lg'} mr="xs">Welcome to</Heading><Logo display='inline-flex' h='25px' />
        </Stack>
        <Box pb={'sm'}>
          <Text fontSize={'lg'} pb={'sm'}>To get started, search for a market below:</Text>
          <Search h={10} w={{base: '100%', desktop: '350px'}} alignSelf='center' />
        </Box>
        <Heading size={'md'} pb={'sm'}>OR</Heading>
        <Box pb={'lg'}>
          <Button colorScheme={'green'} rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-right" />} onClick={handleClick}>
            Browse All Markets
          </Button>
        </Box>
      </Stack>
    </Flex>
    </>
  );
}

export default Home;