const styles = {
  height: 'calc(var(--vh, 1vh) * 100)',
  minHeight: '100vh',
  gridTemplateAreas: {
    base: `"header"
           "main"`,
    desktop: `"header header"
              "sidebar main"`,
  },
  gridTemplateRows: `1fr 20fr`,
  gridTemplateColumns: {
    base: '100%',
    desktop: 'auto 1fr',
  },
  overflow: 'hidden'
};

export default styles;