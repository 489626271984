//Dependencies
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  Box,
  IconButton
} from '@chakra-ui/react';


export default function BackToTop({state, fnc}) {
  let buttonStyles = {
    position: 'sticky',
    alignSelf: 'flex-end',
    bottom: 0,
    opacity: state ? 1 : 0,
    visibility: state ? 'visible' : 'hidden',
    transition: state ? 'visibility 0.3s, opacity 0.3s 0.3s' : 'visibility 0.3s 0.3s, opacity 0.3s',
    zIndex: 'sticky'
  }

  return (
    <Box sx={buttonStyles}>
      <IconButton aria-label={'Back to Top'} icon={<FontAwesomeIcon icon="fa-solid fa-up" />} size={'md'} onClick={fnc} colorScheme={'green'} />
    </Box>
  )
}