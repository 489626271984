//Chakra
import {
  ChakraProvider
} from '@chakra-ui/react';

//Providers
import { AuthenticationProvider } from './providers/authentication';
import { UserProvider } from './providers/user';

//Components
import { RoutesHandler } from './components';

//Helpers
import { ewavesTheme } from './config/theme-config';

//Load and set FontAwesome library for use in components
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation, faArrowDownWideShort, faArrowDownShortWide, faChevronRight as faChevronRightSolid, faUp, faTrashXmark, faPlus as faPlusSolid, faSidebar as faSidebarOn, faBlockQuestion, faAngleLeft, faDownload, faSignOutAlt, faHome, faSquare, faTimes, faChartLine, faWaveTriangle, faTools, faSpinner, faTachometerAltFastest, faTachometerAltSlowest, faTachometerAltAverage, faCaretLeft, faCaretRight, faForward, faBackward, faFileExport, faMagnifyingGlass as faSolidMagnifyingGlass, faBarsSort } from '@fortawesome/pro-solid-svg-icons';
import { faChevronsRight, faChevronsLeft, faChevronLeft, faChevronRight, faSquare as faSquareLight, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { faSearchMinus, faSearchPlus } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus, faMinus, faRedo, faMagnifyingGlass, faXmarkLarge, faArrowDown91, faExpand } from '@fortawesome/pro-regular-svg-icons';
import { faSidebar as faSidebarOff } from '@fortawesome/pro-thin-svg-icons';
import { faCircleXmark, faUserRobotXmarks, faMaximize, faPlus as faPlusSharp, faMinus as faMinusSharp, faSquare as faSquareSharp, faGrid, faGrid2, faGrid2Plus, faDiagramSubtask} from '@fortawesome/sharp-solid-svg-icons';

library.add(faCircleExclamation, faDiagramSubtask, faBarsSort, faSolidMagnifyingGlass, faFileExport, faCircleXmark, faArrowDownShortWide, faUserRobotXmarks, faMinusSharp, faPlusSharp, faSquareSharp, faGrid, faGrid2, faGrid2Plus, faArrowDownWideShort, faChevronsLeft, faChevronsRight, faChevronRightSolid, faUp, faMaximize, faTrashXmark, faPlusSolid, faChevronLeft, faChevronRight, faSidebarOff, faSidebarOn, faExpand, faBlockQuestion, faAngleLeft, faAngleRight, faArrowDown91, faDownload, faSignOutAlt, faHome, faSquare, faXmarkLarge, faMagnifyingGlass, faSquareLight, faTimes, faChartLine, faWaveTriangle, faTools, faSearchPlus, faSearchMinus, faRedo, faSpinner, faTachometerAltFastest, faTachometerAltSlowest, faTachometerAltAverage, faCaretLeft, faCaretRight, faPlus, faMinus, faForward, faBackward);


//Main app component that controls loading initial data and routing to correct pages
const App = () => {

  return (
    <ChakraProvider theme={ewavesTheme}>
        <AuthenticationProvider>
          <UserProvider>
              <RoutesHandler />            
          </UserProvider>
        </AuthenticationProvider>
    </ChakraProvider>
  );
}

export default App;
